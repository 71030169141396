<template>
  <section class="w-full px-inst22 mt-60px mx-auto md:(px-0 w-680px mx-auto) lg:(px-0 w-1060px mt-80px mx-auto)">
    <div class="text-inst28 text-center leading-inst font-600 md:(text-inst36) lg:(text-inst46 text-center leading-inst font-600)">
      {{ $t('customer.fivth.t1') }}
    </div>
    <!-- tab -->
    <div class="mt-40px text-center lg:(mt-40px text-center)">
      <div class="flex justify-center text-inst14 text-inst999 lg:(flex justify-center text-inst17 text-inst999)">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          class="flex justify-center items-center cursor-pointer w-110px py-9px px-10px text-center leading-inst border-t border-b border-instGray lg:(w-196px py-11px text-center leading-inst border-t border-b border-instGray)"
          :class="[
            status === item.status ? 'bg-instGray text-inst111' : '',
            index === 0 ? 'rounded-l-8px border-l border-r lg:(rounded-l-8px border-l border-r)' : '',
            index === 2 ? 'rounded-r-8px border-l border-r lg:(rounded-r-8px border-l border-r)' : ''
          ]"
          @click="useClick(item.status)"
          @mouseenter="useClick(item.status)"
        >
          {{ $t(item.title) }}
        </div>
      </div>
    </div>
    <!-- content -->
    <div class="mt-20px lg:(mt-40px)">
      <div id="wallet-box" class="flex justify-center lg:(flex justify-center)">
        <Wallet v-show="status === 'creditCard'" :cardList="cardList" />
        <Wallet v-show="status === 'wallet'" :cardList="walletList" />
        <Wallet v-show="status === 'pay'" :cardList="payList" />
      </div>
    </div>
  </section>
</template>

<script>
import Wallet from '@/components/wallet.vue'

export default {
  components: {
    Wallet
  },
  data () {
    return {
      status: 'wallet',
      tabList: [
        { title: 'customer.fivth.tab.t1', status: 'creditCard' },
        { title: 'customer.fivth.tab.t2', status: 'wallet' },
        { title: 'customer.fivth.tab.t3', status: 'pay' }
      ],
      cardList: [
        { icon: require('@/assets/images/inst-payments/card/1.png') },
        { icon: require('@/assets/images/inst-payments/card/2.png') },
        { icon: require('@/assets/images/inst-payments/card/3.png') },
        { icon: require('@/assets/images/inst-payments/card/4.png') },
        { icon: require('@/assets/images/inst-payments/card/5.png') },
        { icon: require('@/assets/images/inst-payments/card/6.png') },
        { icon: require('@/assets/images/inst-payments/card/7.png') },
        { icon: require('@/assets/images/inst-payments/card/8.png') },
        { icon: require('@/assets/images/inst-payments/card/9.png') },
        { icon: require('@/assets/images/inst-payments/card/10.png') }
      ],
      walletList: [
        { icon: require('@/assets/images/inst-payments/wallet/1.png') },
        { icon: require('@/assets/images/inst-payments/wallet/2.png') },
        { icon: require('@/assets/images/inst-payments/wallet/3.png') },
        { icon: require('@/assets/images/inst-payments/wallet/4.png') },
        { icon: require('@/assets/images/inst-payments/wallet/5.png') },
        { icon: require('@/assets/images/inst-payments/wallet/6.png') },
        { icon: require('@/assets/images/inst-payments/wallet/7.png') },
        { icon: require('@/assets/images/inst-payments/wallet/8.png') },
        { icon: require('@/assets/images/inst-payments/wallet/9.png') },
        { icon: require('@/assets/images/inst-payments/wallet/10.png') },
        { icon: require('@/assets/images/inst-payments/wallet/11.png') },
        { icon: require('@/assets/images/inst-payments/wallet/12.png') },
        { icon: require('@/assets/images/inst-payments/wallet/13.png') },
        { icon: require('@/assets/images/inst-payments/wallet/14.png') },
        { icon: require('@/assets/images/inst-payments/wallet/15.png') },
        { icon: require('@/assets/images/inst-payments/wallet/16.png') },
        { icon: require('@/assets/images/inst-payments/wallet/17.png') },
        { icon: require('@/assets/images/inst-payments/wallet/18.png') },
        { icon: require('@/assets/images/inst-payments/wallet/19.png') },
        { icon: require('@/assets/images/inst-payments/wallet/20.png') }
      ],
      payList: [
        { icon: require('@/assets/images/inst-payments/pay/1.png') },
        { icon: require('@/assets/images/inst-payments/pay/2.png') },
        { icon: require('@/assets/images/inst-payments/pay/3.png') }
      ]
    }
  },
  methods: {
    useClick (type) {
      if (this.status === type) return
      this.status = type
    }
  }
}
</script>
