<template>
  <div>
    <div v-if="cardList.length >= 5" class="grid grid-cols-inst2 grid-rows-inst10 gap-x-22px gap-y-14px md:(grid grid-cols-inst4 grid-rows-inst5) lg:(w-full h-full grid grid-cols-inst5 grid-rows-inst4 gap-x-15px gap-y-30px)">
      <!-- sm lg -->
      <div
        v-for="(item, index) in cardList"
        :key="index"
        class="inst-border w-154px h-56px flex justify-center items-center md:(hidden) lg:(block w-200px h-73px flex justify-center items-center)"
      >
        <img :src="item.icon" alt="">
      </div>
      <!-- md -->
      <div
        v-for="(mdItem, mdIndex) in cardList"
        :key="mdIndex + 'inst'"
        class="hidden inst-border w-154px h-56px flex justify-center items-cente md:(block) lg:(hidden)"
        :class="[mdIndex === 8 && cardList.length < 11 ? 'md:(col-start-2)' : '', mdIndex === 9 && cardList.length < 11 ? 'md:(col-start-3)' : '']"
      >
        <img :src="mdItem.icon" alt="">
      </div>
    </div>
    <!-- 第三个tab不足5个 居中问题 -->
    <div v-else>
      <!-- lg -->
      <div class="hidden md:(flex justify-center) lg:(flex justify-center)">
        <div
          v-for="(item, index) in cardList"
          :key="index"
          class="inst-border w-154px h-56px lg:(w-200px h-73px flex justify-center items-center)"
          :class="[index === 1 ? 'mx-15px' : '']"
        >
          <img :src="item.icon" alt="">
        </div>
      </div>
      <!-- sm md -->
      <div class="block grid grid-cols-inst2 grid-rows-inst10 gap-x-22px gap-y-14px md:(hidden) lg:(hidden)">
        <div
          v-for="(item, index) in cardList"
          :key="index"
          class="inst-border w-154px h-56px flex justify-center items-center"
        >
          <img :src="item.icon" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardList: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.inst-border {
  background: #FFFFFF;
  box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.inst-area {
  grid-template-areas: '. a b .'
}
</style>
