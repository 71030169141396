<template>
  <section class="w-full px-inst22 mt-60px mx-auto md:(px-0 w-680px mx-auto) lg:(px-0 w-1060px mt-80px mx-auto)">
    <div class="text-inst28 text-center leading-inst font-600 md:(text-inst36) lg:(text-inst46 text-center leading-inst font-600)">
      {{ $t('customer.fouth.t1') }}
    </div>
    <div class="mt-10px text-inst16 text-center leading-inst md:(text-inst17) lg:(mt-10px text-inst22 text-center leading-inst)">
      {{ $t('customer.fouth.t2') }}
    </div>
    <div class="mt-30px lg:(mt-50px)">
      <!-- lg -->
      <div class="hidden lg:(block flex justify-around text-center)">
        <div
          v-for="item in list"
          :key="item.title"
          class="lg:(flex flex-1 flex-col items-center)"
        >
          <div>
            <inst-icon :icon-class="item.icon" style="width:100px;height:100px;" />
          </div>
          <div class="mt-15px text-inst14 leading-inst text-inst888 lg:(mt-30px text-inst20 leading-inst text-inst888)">{{ $t(item.title) }}</div>
        </div>
      </div>
      <!-- sm -->
      <div class="block flex justify-around mb-40px lg:(hidden)">
        <div
          v-for="item in list.slice(0,2)"
          :key="item.title"
          class="flex flex-col items-center text-center"
        >
          <div>
            <inst-icon :icon-class="item.icon" style="width:100px;height:100px;" />
          </div>
          <div class="max-w-120px mt-15px text-inst16 leading-inst text-inst888">{{ $t(item.title) }}</div>
        </div>
      </div>
      <div class="block flex justify-around mb-40px lg:(hidden)">
        <div
          v-for="item in list.slice(2,4)"
          :key="item.title"
          class="flex flex-col items-center text-center"
        >
          <div>
            <inst-icon :icon-class="item.icon" style="width:100px;height:100px;" />
          </div>
          <div class="max-w-120px mt-15px text-inst16 leading-inst text-inst888">{{ $t(item.title) }}</div>
        </div>
      </div>
      <div class="block flex justify-around lg:(hidden)">
        <div
          v-for="item in list.slice(4,6)"
          :key="item.title"
          class="flex flex-col items-center text-center"
        >
          <div>
            <inst-icon :icon-class="item.icon" style="width:100px;height:100px;" />
          </div>
          <div class="max-w-120px mt-15px text-inst16 leading-inst text-inst888">{{ $t(item.title) }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          icon: 'game',
          title: 'customer.fouth.list.t1'
        },
        {
          icon: 'equipment_2',
          title: 'customer.fouth.list.t2'
        },
        {
          icon: 'equipment_3',
          title: 'customer.fouth.list.t3'
        },
        {
          icon: 'equipment_4',
          title: 'customer.fouth.list.t4'
        },
        {
          icon: 'equipment_5',
          title: 'customer.fouth.list.t5'
        },
        {
          icon: 'equipment_6',
          title: 'customer.fouth.list.t6'
        }
      ]
    }
  }
}
</script>
