<template>
  <div>
    <div v-if="FAQType" class="mb-10px text-17px text-inst888 leading-inst font-bold lg:(mb-20px text-20px text-inst888 leading-inst font-bold)">
      {{ FAQType }}
    </div>
    <div
      class="pl-20px pr-10px border-1px border-inste1e rounded-4px cursor-pointer lg:(px-30px border-1px border-inste1e rounded-4px cursor-pointer hover:bg-instebe)"
      v-for="(item, i) in list"
      :key="item.title"
      @click="itemClick(item)"
      :class="[
        item.expand ? 'bg-instFAQ' : '',
        i === list.length - 1 ? '' : 'mb-20px'
      ]"
    >
      <!-- title -->
      <div class="py-20px flex justify-between items-center text-17px text-inst111 leading-inst font-bold lg:(py-20px flex justify-between items-center text-20px text-inst111 font-bold)">
        <div>
          {{ $t(item.title) }}
        </div>
        <!-- icon -->
        <div
          :class="[item.expand ? 'pullUp' : 'pullDown']"
          class="ml-10px flex justify-center items-center"
        >
          <inst-icon icon-class="down" class-name="w-16px h-16px lg:(w-24px h-24px)" />
        </div>
      </div>

      <!-- content -->
      <div
        v-if="item.expand"
        class="mb-20px lg:(mb-40px)"
      >
        <div
          v-for="text in item.content"
          :key="text"
          class="text-14px text-inst888 leading-inst font-normal lg:(max-w-916px text-20px text-inst888 leading-inst font-normal)"
        >{{ $t(text) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  props: {
    /**
     * title string 标题
     * content []string 内容的每一项
     * expand  boolean 当前项是否展开
     */
    newsList: {
      type: Array,
      required: true
    },
    FAQType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      list: this.newsList
    }
  },
  methods: {
    itemClick (item) {
      this.list = this.list.map(child => {
        if (item.title === child.title) {
          child.expand = !item.expand
        } else {
          child.expand = false
        }
        return child
      })
    }
  }
}
</script>

<style scoped>
.pullUp {
  animation: up 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes up {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-180deg) translateY(4px);
  }
}

.pullDown {
  animation: down 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes down {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
