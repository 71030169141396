<template>
 <div id="echart"></div>
</template>

<script>
import * as echarts from 'echarts'
import '../world'
import { options, currentSupportCountry } from './config'

export default {
  data () {
    return {
      instance: null
    }
  },
  mounted () {
    this.init()
    this.echartEvents()
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    init () {
      this.instance = echarts.init(document.getElementById('echart'))
      this.instance.setOption(options)
    },
    echartEvents () {
      // 监听hover事件，非允许hover的国家禁止高亮
      this.instance.on('mouseover', (e) => {
        const support = currentSupportCountry.some(item => e.name === item.name)
        if (!support) {
          this.instance.dispatchAction({
            type: 'downplay',
            name: e.name
          })
        }
      })
      this.instance.on('click', (e) => {
        const support = currentSupportCountry.some(item => e.name === item.name)
        if (!support) {
          this.instance.dispatchAction({
            type: 'unselect',
            name: e.name
          })
        }
      })
    },
    resize () {
      this.instance.resize()
    }
  }
}
</script>
