<template>
  <section class="w-full px-inst22 mt-60px mx-auto md:(px-0 w-680px mx-auto) lg:(px-0 w-1060px mt-80px mx-auto)">
    <div class="text-inst28 text-center leading-inst font-600 md:(text-inst36) lg:(text-inst46 text-center leading-inst font-600)">
      {{ $t('customer.eighth.t1') }}
    </div>
    <div class="mt-30px lg:(mt-40px)">
      <faq :newsList="list"  />
    </div>
    <div
      class="cursor-pointer select-none mt-20px text-17px text-inst111 leading-inst font-bold text-center underline lg:(mt-40px text-24px text-inst000 leading-inst font-bold text-center underline)"
      @click="toMore"
    >
      {{ $t('customer.eighth.more') }}
    </div>
  </section>
</template>

<script>
import Faq from '@/components/faq'

export default {
  components: {
    faq: Faq
  },
  data () {
    return {
      list: [
        {
          title: 'faq.faq1.title',
          content: ['faq.faq1.content1', 'faq.faq1.content2', 'faq.faq1.content3', 'faq.faq1.content4'],
          expand: false
        },
        {
          title: 'faq.faq2.title',
          content: ['faq.faq2.content1'],
          expand: false
        }
      ]
    }
  },
  methods: {
    toMore () {
      this.$router.push({
        name: 'faq'
      })
    }
  }
}
</script>
