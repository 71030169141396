<template>
  <section class="background mt-10px mx-auto pt-25px pb-30px lg:(mt-30px mx-auto pt-50px pb-60px)">
    <div class="w-full px-inst22 mx-auto text-inst28 text-center leading-inst font-600 md:(px-0 w-680px mx-auto text-inst36) lg:(px-0 w-1060px mx-auto text-inst46 text-center leading-inst font-600)">
      {{ $t('customer.seventh.t1') }}
    </div>
    <div class="w-full px-inst22 mx-auto mt-10px text-inst16 text-center leading-inst md:(w-680px text-inst17) lg:(px-0 w-1060px mx-auto mt-10px text-inst22 text-center leading-inst)">
      {{ $t('customer.seventh.t2') }}
    </div>
    <div class="mt-30px lg:(mt-40px)">
      <!-- word map -->
      <div class="px-0 flex flex-col mb-20px px-0px md:(flex-row justify-center) lg:(flex-row justify-center px-40px mb-40px)">
        <WordEchart class="w-full h-240px md:(min-h-360px) lg:(w-1040px min-h-570px mr-0px)" />
        <div class="flex-1 lg:(pl-100px)">
          <Count class="px-20px lg:(px-0 max-w-200px)" />
        </div>
      </div>
      <!-- images -->
      <div class="px-0 grid grid-flow-col grid-cols-inst-2 grid-rows-inst9 gap-4px lg:(px-40px grid grid-flow-col grid-cols-inst6 grid-rows-inst3 gap-8px)">
        <div
          v-for="(item, index) in list"
          :key="index"
          :class="[item.place === 2 ? 'row-span-2' : '']"
        >
          <img v-lazy="item.img"  alt="" class="w-full h-full">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WordEchart from './echart'
import Count from './count.vue'

export default {
  components: {
    WordEchart,
    Count
  },
  data () {
    return {
      list: [
        { img: require('@/assets/images/inst-anywhere/1.jpg'), place: 2 },
        { img: require('@/assets/images/inst-anywhere/2.jpg'), place: 1 },
        { img: require('@/assets/images/inst-anywhere/3.jpg'), place: 1 },
        { img: require('@/assets/images/inst-anywhere/4.jpg'), place: 2 },
        { img: require('@/assets/images/inst-anywhere/5.jpg'), place: 2 },
        { img: require('@/assets/images/inst-anywhere/6.jpg'), place: 1 },
        { img: require('@/assets/images/inst-anywhere/7.jpg'), place: 1 },
        { img: require('@/assets/images/inst-anywhere/8.jpg'), place: 2 },
        { img: require('@/assets/images/inst-anywhere/9.jpg'), place: 2 },
        { img: require('@/assets/images/inst-anywhere/10.jpg'), place: 1 },
        { img: require('@/assets/images/inst-anywhere/11.jpg'), place: 1 },
        { img: require('@/assets/images/inst-anywhere/12.jpg'), place: 2 }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.background {
  background: linear-gradient(180deg, #F6F8F9 0%, #F2F5F7 100%);
}
</style>
