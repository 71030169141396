<template>
  <div class="h-full flex flex-col justify-center items-center md:(flex-col justify-center items-start)">
    <div v-for="(item, index) in list" :key="item.langkey" class="border-bottom text-inst666 py-15px md:(w-full) lg:(py-30px)">
      <countTo :ref="`ref${index}`" :startVal='0' :endVal='item.count' :suffix="item.suffix" :duration='3000' class="title" :id="index=== 1 ? 'contain' : ''" />
      <div class="desc">{{ $t(item.langKey) }}</div>
    </div>
    <div class="text-inst666 py-15px lg:(py-30px)">
      <div class="title text-instGreen">{{ $t('customer.seventh.t5') }}</div>
      <div class="desc">{{ $t('customer.seventh.t6') }}</div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'

export default {
  components: {
    countTo
  },
  data () {
    return {
      time: 0,
      list: [
        { count: 20, langKey: 'customer.seventh.t3' },
        { count: 20000, langKey: 'customer.seventh.t4', suffix: '+' }
      ]
    }
  },
  mounted () {
    this.start()
  },
  methods: {
    start () {
      const io = new IntersectionObserver(this.callback, {
        threshold: 1
      })
      io.observe(document.getElementById('contain'))
    },
    callback () {
      if (this.time >= 2) return
      this.time += 1
      this.list.forEach((_, index) => {
        this.$refs[`ref${index}`][0].start()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.border-bottom {
  @apply border-solid border-black border-opacity-20 border-b-1px;
}
.title {
  @apply block font-700 text-32px leading-30px text-center md:text-left lg:text-44px  lg:leading-56px;
}
.desc {
  @apply text-20px leading-40px text-center md:text-left lg:text-20px lg:leading-33px;
}
</style>
