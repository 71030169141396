import {
  nameMap, currentSupportCountry, Germany, Hungary,
  Austria, Japan, Philippines, Indonesia, Malaysia, England, France
} from './word'
import { getDevice } from '@/utils'
export * from './word'

function lineDistance (params) {
  const device = getDevice()
  const country = params.text
  const x = params.rect.x
  const y = params.rect.y
  const support = currentSupportCountry.some(item => country === item.name)
  if (!support) return
  if (country === Hungary) {
    if (device === 'large') {
      return {
        x: x + 35,
        y: y - 5,
        align: 'left'
      }
    } else if (device !== 'large') {
      return {
        x: x + 25,
        y: y - 10,
        align: 'left'
      }
    }
  } else if (country === Germany) {
    if (device === 'large') {
      return {
        x: x + 30,
        y: y - 10,
        align: 'left'
      }
    } else if (device !== 'large') {
      return {
        x: x + 20,
        y: y - 30,
        align: 'left'
      }
    }
  } else if (country === Austria) {
    if (device === 'large') {
      return {
        x: x + 20,
        y: y + 25,
        align: 'left'
      }
    } else if (device !== 'large') {
      return {
        x: x + 15,
        y: y + 20,
        align: 'left'
      }
    }
  } else if (country === France) {
    if (device === 'large') {
      return {
        x: x + 90,
        y: y + 10,
        align: 'left'
      }
    } else if (device === 'medium') {
      return {
        x: x + 30,
        y: y + 10,
        align: 'left'
      }
    } else if (device === 'small') {
      return {
        x: x + 10,
        y: y + 10,
        align: 'left'
      }
    }
  } else if (country === England) {
    if (device === 'large') {
      return {
        x: x - 30,
        y: y + 15,
        align: 'left'
      }
    } else if (device !== 'large') {
      return {
        x: x - 10,
        y: y - 30,
        align: 'left'
      }
    }
  } else if (country === Japan) {
    if (device === 'small') {
      return {
        x: x + 25,
        y: y + 10,
        align: 'left'
      }
    }
  } else if (country === Indonesia) {
    if (device !== 'large') {
      return {
        x: x - 40,
        y: y + 30,
        align: 'left'
      }
    }
  } else if (country === Malaysia) {
    if (device === 'medium') {
      return {
        x: x - 60,
        y: y + 40,
        align: 'left'
      }
    } else if (device === 'small') {
      return {
        x: x - 60,
        y: y + 10,
        align: 'left'
      }
    }
  } else if (country === Philippines) {
    if (device !== 'large') {
      return {
        x: x - 30,
        y: y - 10,
        align: 'left'
      }
    }
  }
}

export const options = {
  series: [
    {
      type: 'map', // 类型
      map: 'world', // 地图类型
      roam: false, // 是否开启鼠标缩放和平移漫游 默认不开启 如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move' 设置成 true 为都开启
      zoom: 1.2, // 缩放
      selectedMode: 'multiple',
      label: {
        show: false, // 是否显示对应地名
        fontSize: 12
      },
      itemStyle: {
        areaColor: '#ccc', // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
        borderWidth: 0.1, // 描边线宽 为 0 时无描边
        borderColor: '#fff', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
        borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
      },
      // 高亮状态下区域样式
      emphasis: {
        focus: 'self', // 在高亮一个图形的时候，淡出所有其它的图形
        label: {
          color: '#000'
        },
        itemStyle: {
          areaColor: '#02d250' // 地图区域的颜色
        }
      },
      select: {
        label: {
          show: true,
          color: '#666'
        },
        itemStyle: {
          areaColor: '#02d250'
        }
      },
      // 标签引导线的计算函数
      labelLayout: lineDistance,
      labelLine: {
        show: true,
        showAbove: true,
        lineStyle: {
          width: 0.5,
          color: '#02d250',
          cap: 'round'
        }
      },
      // 自定义地区的名称映射
      nameMap: nameMap,
      // 地图系列中的数据内容数组 数组项可以为单个数值
      data: currentSupportCountry
    }
  ]
}
