<template>
  <section class="w-full px-inst22 mt-60px mx-auto md:(px-0 w-680px mx-auto) lg:(px-0px w-1060px mt-80px mx-auto)">
    <div class="text-inst28 text-center leading-inst font-600 md:(text-inst36) lg:(text-inst46 text-center leading-inst font-600)">
      {{ $t('customer.second.t1') }}
    </div>
    <div class="mt-10px text-inst16 text-center leading-inst md:(text-inst17) lg:(mt-10px text-inst22 text-center leading-inst)">
      {{ $t('customer.second.t2') }}
    </div>
    <div class="mt-20px flex flex-col justify-center lg:(mt-30px flex flex-row justify-around)">
      <div
        v-for="(item, index) in list"
        :key="item.title1"
        class="flex flex-col items-center px-22px md:(px-0) lg:(px-0 flex flex-col items-center)"
        :class="[index === list.length - 1 ? '' : 'mb-30px lg:(mb-0)']"
      >
        <div>
          <inst-icon :icon-class="item.icon" style="width:100px;height:100px;" />
        </div>
        <div class="mt-20px text-inst20 text-center leading-inst font-600 text-inst111 md:(max-w-370px text-inst17) lg:(max-w-310px mt-30px text-inst20 text-center leading-inst font-600 text-inst111)">{{ $t(item.title1) }}</div>
        <div class="mt-5px text-inst16 text-center leading-inst font-400 text-inst888 md:(max-w-370px text-inst15 mt-5px) lg:(max-w-310px mt-5px text-inst17 text-center leading-inst font-400 text-inst888)">{{ $t(item.title2) }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          icon: 'features',
          title1: 'customer.second.list.t1',
          title2: 'customer.second.list.t2'
        },
        {
          icon: 'scan',
          title1: 'customer.second.list.t3',
          title2: 'customer.second.list.t4'
        },
        {
          icon: 'coin',
          title1: 'customer.second.list.t5',
          title2: 'customer.second.list.t6'
        }
      ]
    }
  }
}
</script>
