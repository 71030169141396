<template>
  <div class="home">
    <inst-banner />
    <inst-introduce />
    <inst-rental />
    <inst-line />
    <inst-payments />
    <inst-pay />
    <inst-anywhere />
    <inst-faq />
  </div>
</template>

<script>
import Banner from './components/banner.vue'
import ProductIntroduce from './components/inst-introduce.vue'
import ProductRental from './components/inst-rental.vue'
import ProductLine from './components/inst-line.vue'
import ProductPayments from './components/inst-payments.vue'
import InstPay from './components/inst-pay.vue'
import ProductAnywhere from './components/inst-anywhere/index.vue'
import ProductFaq from './components/inst-faq.vue'

export default {
  components: {
    'inst-banner': Banner,
    'inst-introduce': ProductIntroduce,
    'inst-rental': ProductRental,
    'inst-line': ProductLine,
    'inst-payments': ProductPayments,
    'inst-anywhere': ProductAnywhere,
    'inst-faq': ProductFaq,
    'inst-pay': InstPay
  },
  data () {
    return {}
  }
}
</script>
